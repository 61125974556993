import React from 'react'
import { useNavigate } from 'react-router-dom';

const RoomCard = ({ img, cta = "/", roomType }) => {

    const navigate = useNavigate();

    const getSliderClass = () => {
        switch (roomType) {
            case 'Private':
                return 'revealator-slideright revealator-duration15 revealator-delay2';
            case 'Dorm':
                return 'revealator-slideleft revealator-duration15 revealator-delay2';
            default:
                return '';
        }
    };

    return (
        <div className={"relative bg-center bg-cover w-[500px] h-[500px] sm:max-md:w-screen sm:max-md:h-[400px] bg-[url('" + img + "')] " + getSliderClass()}>
            <div className='absolute py-[20px] sm:px-[30px] bottom-0 w-[100%] h-[auto] bg-black bg-opacity-60 flex flex-col items-center'>
                <div className=" mb-2 text-center font-['Poppins'] sm:max-md:text-[2rem] text-[2.2rem] sm:max-md:w-full text-floralwhite">
                    {roomType}
                </div>
                <button
                    onClick={() => navigate(cta)}
                    className="rounded-md cursor-pointer text-floralwhite sm:max-md:text-[1.2rem] text-[1.5rem] bg-firebrick-100 lg:w-[50%] lg:h-[55px] sm:h-[55px] md:h-[55px] sm:w-[100%] md:w-[60%] sm:mt-4 lg:mt-0 md:mt-0 py-2.5 px-[2%] box-border">
                    Book now
                </button>
            </div>
        </div>
    )
}

const RoomSelector = ({ ctaDorm, ctaPrivate }) => {
    return (
        <div className="w-full bg-[url('/public/hero_homepage.jpg')]">
            <div className='backdrop-blur-lg pb-[70px] lg:xl:pb-[70px] sm:pb-[0px] w-full h-full'>
                <div className="pt-10 text-center font-['Poppins'] sm:max-md:text-[2rem] text-[4rem] sm:max-md:w-full text-floralwhite revealator-zoomin revealator-duration15 revealator-delay2">
                    Select your Room..
                </div>
                <div className='pt-10 flex flex-row sm:max-md:flex-col justify-evenly sm:max-md:h-[100%]'>
                    <RoomCard img={"/public/private-room.jpg"} roomType={"Private"} cta={ctaPrivate} />
                    <RoomCard img={"/public/dorm-room.jpg"} roomType={"Dorm"} cta={ctaDorm} />
                </div>
            </div>
        </div>
    )
}

export default RoomSelector