import React from 'react'
import { urlMap } from '../constants/common'
import { detailMap } from '../constants/common'
import { ArrowRightIcon } from '@heroicons/react/20/solid'
import { IMAGE_BASE_URL } from "../constants/common";

function classNames(...classes) {
    return classes.filter(Boolean).join(' ')
}

const LocationCard = ({ inverted = false, location = "Vagator" }) => {

    function getBGImageClass(location) {
        let commonStyleclasses = "sm:md:w-full bg-center top-0 overflow-hidden lg:xl:w-[80%] lg:h-[566px] sm:h-[350px] object-cover bg-no-repeat bg-clip-content z-[0] relative " + (inverted ? "revealator-slideleft revealator-duration15 revealator-delay2 " : "revealator-slideright revealator-duration15 revealator-delay2 ")
        if (location == "Arambol")
            return commonStyleclasses + "bg-[url('/public/arambol-beach-2.jpg')] bg-cover"
        if (location == "Vagator")
            return commonStyleclasses + "bg-[url('/public/cardloc2@3x.jpg')] bg-cover"
        if (location == "Sonmarg")
            return commonStyleclasses + "bg-[url('/public/sonmarg-1.jpg')] bg-cover"
        if (location == "Dharamkot")
            return commonStyleclasses + "bg-[url('/public/dharamkot_1.jpg')] bg-cover"
        if (location == "Mandrem")
            return commonStyleclasses + "bg-[url('/public/mandrem_1.jpg')] bg-cover"

    }

    const handleLocationClick = () => {
        // console.log(window.location);

        const origin = window.location.origin;

        // const pathname = window.location.pathname.replaceAll('/', '');

        // const originPath = origin + '/' + pathname + '/';

        window.open(`${origin}/${urlMap[location]}`);
    };

    return (
        <div className={classNames("object-cover flex",
            inverted ? "lg:flex-row-reverse sm:max-md:flex-col-reverse" : "sm:flex-row sm:max-md:flex-col-reverse")}>
            <div
                className={getBGImageClass(location)}>
                {location == "Mandrem" ? <img className='absolute z-50 w-[15%]' src={IMAGE_BASE_URL + 'new_label.png'} /> : <></>}
                <div className={classNames("bg-[#04040441] lg:backdrop-blur-md md:backdrop-filter-none top-0 h-full flex flex-col justify-center",
                    inverted ? "lg:text-right sm:text-center pr-10 sm:p-5" : "lg:text-left sm:text-center pl-10 sm:p-10")} >
                    <div className="object-cover font-semibold text-50xl sm:text-[40px] font-['Poppins'] w-full text-floralwhite">
                        {location}
                    </div>
                    <div className="object-cover mx-1 text-5xl sm:text-[18px] sm:my-5 font-['Poppins'] text-floralwhite">
                        {detailMap[location].desc}
                    </div>
                    <button
                        onClick={handleLocationClick}
                        className={classNames("rounded-md cursor-pointer flex items-center gap-1 text-floralwhite text-[1.5rem] bg-firebrick-100 w-fit mt-[50px] h-[60px] py-2.5 px-[2%] box-border",
                            inverted ? "lg:ml-auto lg:mr-0 sm:ml-auto sm:mr-auto sm:my-0" : "lg:mr-auto lg:ml-0 sm:ml-auto sm:mr-auto sm:my-0")}>
                        <span> Explore Now</span> <ArrowRightIcon className='h-[30px] w-[30px]' />
                    </button>
                </div>
            </div>
            <img
                className={`m-0 p-0 relative object-cover lg:w-[20%] lg:h-[566px] sm:hidden lg:block ${inverted ? `revealator-slideright revealator-duration15 revealator-delay2` : `revealator-slideleft revealator-duration15 revealator-delay2`}`}
                alt=""
                src={IMAGE_BASE_URL + detailMap[location].img}
            />
        </div>
    )
}

export default LocationCard