import React from 'react'
import { footerLinks } from '../constants/common'
import { IMAGE_BASE_URL } from "../constants/common";
import { useNavigate } from 'react-router-dom';

const FooterComponent = () => {
  const navigate = useNavigate();
  return (
    <footer className="bg-floralwhite mt-0 w-screen lg:p-[30px] sm:py-[30px] sm:px-[15px]">
      <div className="flex flex-row sm:max-md:flex-col sm:items-start md:items-center lg:items-center lg:justify-between md:justify-between p-1 revealator-zoomin revealator-duration15 revealator-delay2">
        <img
          className="float-left relative sm:max-md:m-[auto] w-[200px] h-[200px] object-cover"
          alt="mitra-logo"
          src={IMAGE_BASE_URL + "siteLogo2x.png"}
        />
        <ul className="font-['Poppins'] sm:max-md:text-xs text-xl list-none flex flex-col items-center gap-y-2 p-0 items-baseline">
          {footerLinks.map((item) => {
            return (<li key={item}
              onClick={() => navigate("/vagator-location-page")}
              className="text-black capitalise cursor-pointer">
              {item}
            </li>)
          })
          }
        </ul>
        <div className="sm:w-[100%] lg:w-[auto] md:w-[auto] font-['Poppins'] text-black mt-5 flex flex-col sm:max-md:flex-row sm:max-md:ml-0 sm:max-md:gap-x-4 gap-y-4 ">
          <span className="relative text-[0.8rem] sm:max-md:text-[0.8rem]">Connect with us on your favorite social media ..</span>

          <div className='flex items-center items-center sm:justify-end lg:justify-center md:justify-center gap-5 w-[100%]'>
            <img
              className="relative w-[25px] h-[25px] object-contain"
              alt="mitra-logo"
              src={IMAGE_BASE_URL + "instagram-logo.svg"}
            />
            <img
              className="relative w-[25px] h-[25px] object-contain"
              alt="mitra-logo"
              src={IMAGE_BASE_URL + "facebook-logo.svg"}
            />
          </div>

        </div>
      </div>
    </footer>
  )
}

export default FooterComponent