import React from "react";
import SiteHeader from "../components/header";
import { detailMap } from "../constants/common";
import FooterComponent from "../components/footer";
import Gallery from "../components/gallery";
import { galleryImageMap } from "../constants/common";
import RoomSelector from "../components/roomSelector";
import { IMAGE_BASE_URL } from "../constants/common";
import { useEffect } from "react";

const PageTemplate = ({ location }) => {

  useEffect(() => {
    // console.log("Loadedd");
    $(window).trigger("scroll");
    Revealator.effects_padding = '-550';
  }, [])

  const handleBookNow = () => {
    const phoneNumber = '9925881444';

    const currentPath = window.location.pathname;

    const location = currentPath.split("/")[currentPath.split("/").length - 1];

    const dynamicMessage = `Hello! I would like to confirm my booking at ${hostelName(
      location
    )}.\n\nThank you!`;

    const encodedMessage = encodeURIComponent(dynamicMessage);
    const encodedPhoneNumber = encodeURIComponent(phoneNumber);

    const whatsappURL = `https://wa.me/${encodedPhoneNumber}?text=${encodedMessage}`;

    window.open(whatsappURL, "_blank", "noopener,noreferrer");
  };

  const hostelName = (locationName) => {
    switch (locationName) {
      case "arambol":
        return "Arambol Goa Hostel";
      case "vagator":
        return "Vagator Goa Hostel";
      case "sonmarg":
        return "Sonmarg Hostel";

      case "dharamkot":
        return "Dharamkot Hostel";

      case "mandrem":
        return "Mandrem Hostel";

      default:
        return locationName;
    }
  };

  const getLocationImageClass = (location) => {
    let commonStyleclasses = "bg-no-repeat bg-center w-screen bg-cover 2xl:h-[750px] xl:h-[500px] lg:h-[450px] sm:h-[400px] "
    if (location == "Arambol")
      return commonStyleclasses + "bg-[url('/public/arambol_beach_1.jpg')]"
    if (location == "Vagator")
      return commonStyleclasses + "bg-[url('/public/vagator_1.jpg')]"
    if (location == "Sonmarg")
      return commonStyleclasses + "bg-[url('/public/sonmarg_1.jpg')]"
    if (location == "Dharamkot")
      return commonStyleclasses + "bg-[url('/public/dharamkot_1.jpg')]"
    if (location == "Mandrem")
      return commonStyleclasses + "bg-[url('/public/mandrem_1.jpg')]"

  }

  const getLocationGmapSource = (location) => {
    switch (location) {
      case "Arambol":
        return "https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d240.11828450117704!2d73.69805711648674!3d15.650709143898126!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x3bbfef20fc8bb177%3A0xa4e4876c60b1cd60!2sMitra%20Hostel!5e0!3m2!1sen!2sin!4v1705493132433!5m2!1sen!2sin";
      case "Vagator":
        return "https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d2717.3011802635783!2d73.73507340842744!3d15.59997182027421!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x3bbfe938d224f627%3A0x64d78b739ded45b6!2sMitra%20Hostel%20Vagator!5e0!3m2!1sen!2sin!4v1705568693010!5m2!1sen!2sin";
      case "Sonmarg":
        return "https://www.google.com/maps/embed?pb=!1m14!1m8!1m3!1d1647.9438841009933!2d75.29583670894122!3d34.302404060052375!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x38e235184d3f048f%3A0x4e7ae34fede30ba7!2sMitra%20Hostel%20sonamarg!5e0!3m2!1sen!2sin!4v1705568631928!5m2!1sen!2sin";

      case "Dharamkot":
        return "https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d5674.725141032628!2d76.32719035379344!3d32.252384311713385!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x391b577e52be4537%3A0x209ca2688beeb1d0!2sMitra%20Hostel%20Dharamkot!5e0!3m2!1sen!2sin!4v1705660462926!5m2!1sen!2sin";

      case "Mandrem":
        return "https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d240.11828450117704!2d73.69805711648674!3d15.650709143898126!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x3bbfef20fc8bb177%3A0xa4e4876c60b1cd60!2sMitra%20Hostel!5e0!3m2!1sen!2sin!4v1705493132433!5m2!1sen!2sin";

      default:
        return '';
    }
  }

  return (
    <div className="absolute w-screen p-0 bg-black">
      <SiteHeader />
      <div className="mt-[100px] w-full bg-black" />

      <div
        className={getLocationImageClass(location)}
      //
      >
        <div className="relative w-full h-full after_box flex flex-row items-center justify-center">
          <div className="relative z-10 h-[500px] sm:max-md:h-[320px] top-0 w-[70%] sm:max-md:w-full text-center text-gray-50 overflow-visible flex flex-row items-center justify-center">
            <div className="relative h-[auto] top-[0] left-[0] w-full flex flex-col items-center text-center justify-start gap-[5%]">
              <div className="inline-block font-['Poppins'] font-bold lg:text-[90px] sm:text-[55px] revealator-zoomin revealator-duration15 revealator-delay2">
                {location}
              </div>
              <div className="relative top-[0] h-full w-full flex flex-col items-center overflow-visible justify-center gap-[25px] text-8xl">
                <div className="inline-block font-['Poppins'] text-[25px] sm:max-md:text-[20px] revealator-slideup revealator-duration15 revealator-delay10">
                  {detailMap[location].desc}
                </div>
                <button
                  onClick={handleBookNow}
                  className="rounded-md cursor-pointer text-floralwhite sm:max-md:text-[1.2rem] text-[1.5rem] bg-firebrick-100 lg:w-[50%] lg:h-[55px] sm:h-[55px] md:h-[55px] sm:w-[90%] md:w-[60%] sm:mt-4 lg:mt-0 md:mt-0 py-2.5 px-[2%] box-border revealator-slideup revealator-duration15 revealator-delay15"
                >
                  Book now
                </button>
              </div>
            </div>
          </div>
        </div>
      </div>
      <Gallery images={galleryImageMap[location]} location={location} />
      <RoomSelector />
      <div className="bg-firebrick-100 overflow-hidden p-[10px]">
        <div className="relative text-floralwhite flex flex-col items-center top-[2%] h-[10%] w-full">
          <div className="font-bold text-[3rem] sm:max-md:text-[2rem] revealator-zoomin revealator-duration15 revealator-delay2">
            How to reach
          </div>
        </div>
        <div className=" h-full w-full lg:pb-[50px] lg:pt-[20px] lg:px-[100px] sm:px-[10px]">
          <iframe
            className="xl:lg:h-[665px] w-[100%] sm:h-[300px] border-[none] revealator-zoomin revealator-duration15 revealator-delay2"
            src={getLocationGmapSource(location)}
            allowFullScreen=""
            loading="lazy"
            referrerPolicy="no-referrer-when-downgrade"
          ></iframe>
        </div>
        <div className="bg-white py-[30px] px-[30px] xl:grid sm:grid lg:grid md:grid lg:grid-cols-3 sm:grid-cols-1 xl:lg:gap-[50px] sm:gap-[30px]">
          <div className="relative overflow-hidden lg:xl:h-[600px] sm:h-[300px] revealator-slideright revealator-duration15 revealator-delay2">
            <img className="h-[100%] w-[100%]" src={IMAGE_BASE_URL + "cardloc2@3x.jpg"} />
            <div className="absolute py-[40px] px-[30px] bottom-0 w-[100%] h-[100%] bg-gradient-to-t from-black from-10% to-80% to-[transparent] flex flex-col items-center justify-end">
              <div className=" mb-5 text-center font-['Poppins'] sm:max-md:text-[2rem] text-[2.2rem] sm:max-md:w-full text-floralwhite">
                Sonmarg
              </div>
              <button className="rounded-md cursor-pointer text-floralwhite sm:max-md:text-[1.2rem] text-[1.5rem] bg-firebrick-100 lg:w-[50%] lg:h-[55px] sm:h-[55px] md:h-[55px] sm:w-[100%] md:w-[60%] sm:mt-4 lg:mt-0 md:mt-0 py-2.5 px-[2%] box-border">
                Explore now
              </button>
            </div>
          </div>
          <div className="relative overflow-hidden lg:xl:h-[600px] sm:h-[300px] revealator-zoomin revealator-duration15 revealator-delay2">
            <img className="h-[100%] w-[100%]" src={IMAGE_BASE_URL + "cardloc1@3x.jpg"} />
            <div className="absolute py-[40px] px-[30px] bottom-0 w-[100%] h-[100%] bg-gradient-to-t from-black from-10% to-80% to-[transparent] flex flex-col items-center justify-end">
              <div className=" mb-5 text-center font-['Poppins'] sm:max-md:text-[2rem] text-[2.2rem] sm:max-md:w-full text-floralwhite">
                Vagator
              </div>
              <button className="rounded-md cursor-pointer text-floralwhite sm:max-md:text-[1.2rem] text-[1.5rem] bg-firebrick-100 lg:w-[50%] lg:h-[55px] sm:h-[55px] md:h-[55px] sm:w-[100%] md:w-[60%] sm:mt-4 lg:mt-0 md:mt-0 py-2.5 px-[2%] box-border">
                Explore now
              </button>
            </div>
          </div>
          <div className="relative overflow-hidden lg:xl:h-[600px] sm:h-[300px] revealator-slideleft revealator-duration15 revealator-delay2">
            <img className="h-[100%] w-[100%]" src={IMAGE_BASE_URL + "arambol-beach-2.jpg"} />
            <div className="absolute py-[40px] px-[30px] bottom-0 w-[100%] h-[100%] bg-gradient-to-t from-black from-10% to-80% to-[transparent] flex flex-col items-center justify-end">
              <div className=" mb-5 text-center font-['Poppins'] sm:max-md:text-[2rem] text-[2.2rem] sm:max-md:w-full text-floralwhite">
                McLeodganj
              </div>
              <button className="rounded-md cursor-pointer text-floralwhite sm:max-md:text-[1.2rem] text-[1.5rem] bg-firebrick-100 lg:w-[50%] lg:h-[55px] sm:h-[55px] md:h-[55px] sm:w-[100%] md:w-[60%] sm:mt-4 lg:mt-0 md:mt-0 py-2.5 px-[2%] box-border">
                Explore now
              </button>
            </div>
          </div>
        </div>
      </div>
      <FooterComponent />
    </div>
  );
};

export default PageTemplate;
