import { useEffect } from "react";
import {
  Routes,
  Route,
  useNavigationType,
  useLocation,
} from "react-router-dom";
import ArambolLocationPage from "./pages/arambol-location-page";
import HomePage from "./pages/home-page";
import Careers from "./pages/careers";

import SonmargLocationPage from "./pages/sonmarg-location-page";
import VagatorLocationPage from "./pages/vagator-location-page";
import PageTemplate from "./page-template/page_template";


function App() {
  const action = useNavigationType();
  const location = useLocation();
  const pathname = location.pathname;

  useEffect(() => {
    if (action !== "POP") {
      window.scrollTo(0, 0);
    }
  }, [action, pathname]);

  useEffect(() => {
    let title = "";
    let metaDescription = "";

    switch (pathname) {
      case "/":
        title = "";
        metaDescription = "";
        break;
      case "/home-page":
        title = "";
        metaDescription = "";
        break;
      case "/sonmarg-location-page":
        title = "";
        metaDescription = "";
        break;
      case "/vagator-location-page":
        title = "";
        metaDescription = "";
        break;
    }

    if (title) {
      document.title = title;
    }

    if (metaDescription) {
      const metaDescriptionTag = document.querySelector(
        'head > meta[name="description"]'
      );
      if (metaDescriptionTag) {
        metaDescriptionTag.content = metaDescription;
      }
    }
  }, [pathname]);

  return (
    <Routes>
      <Route path="/" element={<HomePage />} />
      <Route path="/arambol/" element={<PageTemplate location="Arambol" />} />
      <Route path="/sonmarg/" element={<PageTemplate location="Sonmarg" />} />
      <Route path="/vagator/" element={<PageTemplate location="Vagator" />} />
      <Route path="/dharamkot/" element={<PageTemplate location="Dharamkot" />} />
      <Route path="/mandrem/" element={<PageTemplate location="Mandrem" />} />
      <Route path="/careers" element={<Careers />} />
    </Routes>
  );
}
export default App;
