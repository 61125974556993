import { Fragment } from 'react'
import { Menu, Transition } from '@headlessui/react'
import { ChevronDownIcon } from '@heroicons/react/20/solid'
import { hostelLocations } from '../constants/common'

function classNames(...classes) {
  return classes.filter(Boolean).join(' ')
}

export default function Example({ location, setLocation }) {
  return (
    <Menu as="div" className="relative inline-block lg:h-[200px] overflow-auto lg:w-[70%] md:w-[60%] sm:w-full z-[400] sm:h-[200px] text-left overflow-visible">
      <div>
        <Menu.Button className="inline-flex w-full h-[65px] justify-between items-center gap-x-1.5 p-0 overflow-hidden rounded-md bg-white text-sm font-semibold text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 hover:bg-gray-50">
          <span className={"text-gray-500 lg:md:text-[18px] sm:text-[16px] px-3 py-2"}>{location}</span>
          <div className='h-[100%] w-[60px] bg-firebrick-100 flex justify-center items-center'>
            <ChevronDownIcon className="-mr-1 right-0 text-white h-[40px] w-[40px]" aria-hidden="true" />
          </div>


        </Menu.Button>
      </div>

      <Transition
        as={Fragment}
        enter="transition ease-out duration-100"
        enterFrom="transform opacity-0 scale-95"
        enterTo="transform opacity-100 scale-100"
        leave="transition ease-in duration-75"
        leaveFrom="transform opacity-100 scale-100"
        leaveTo="transform opacity-0 scale-95"
      >
        <Menu.Items className="absolute right-0 mt-0 w-full gray-scrollbar lg:md:h-[200px] z-5 sm:h-[130px] overflow-scroll origin-top-right rounded-md bg-white shadow-lg ring-1 ring-black ring-opacity-5 focus:outline-none">
          <div className="py-1">
            {
              hostelLocations.map(loc => {
                return (
                  <Menu.Item key={loc}>
                    <span
                      onClick={() => setLocation(loc)}
                      href="#"
                      className={classNames(
                        'text-gray-700',
                        'block hover:bg-slate-300 px-4 py-2 lg:md:text-[18px] sm:text-[16px] font-bold text-left'
                      )}
                    >
                      {loc}
                    </span>
                  </Menu.Item>
                )
              })
            }
          </div>
        </Menu.Items>
      </Transition>
    </Menu>
  )
}