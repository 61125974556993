const headerLinks = ["Destination", "About", "Careers"]
const footerLinks = ["Privacy Policy", "Refund policy", "F.A.Q", "About"]
const hostelLocations = ["Vagator", "Arambol", "Sonmarg", "McLeod", "Dharamkot", "Mandrem"]

const urlMap = {
    "Vagator": "vagator",
    "Arambol": "arambol",
    "Sonmarg": "sonmarg",
    "McLeod": "mcleod",
    "Dharamkot": "dharamkot",
    "Mandrem": "mandrem"
}

const galleryImageMap = {
    "Sonmarg": [
        "/vagator-1.jpg",
        "/arambol-beach-2.jpg",
        "/armb-1.jpg",
    ],
    "Arambol": [
        "/vagator-1.jpg",
        "/arambol-beach-2.jpg",
        "/armb-1.jpg",
        "/armb-2.jpg"
    ],
    "Vagator": [
        "/vagator-1.jpg",
        "/arambol-beach-2.jpg",
        "/armb-1.jpg",
    ],
    "Mandrem": [
        "/mandrem_1.jpg",
        "/arambol-beach-2.jpg",
        "/armb-1.jpg",
    ]
}

const detailMap = {
    "Vagator": {
        desc: "At our Vagator Goa Hostel, Enjoy the serene Sunset ",
        img: "vagator_1.jpg"
    },
    "Arambol": {
        desc: "At our Arambol Goa Hostel, Enjoy the serene sunset",
        img: "arambol_beach_1.jpg"
    },
    "Sonmarg": {
        desc: "At our Sonmarg Hostel, Enjoy the fresh air",
        img: "sonmarg_1.jpg"
    },
    "McLeod": {
        desc: "At our Vagator Goa Hostel, Enjoy the serene sunset",
        img: "arambol-beach-2.jpg"
    },
    "Dharamkot": {
        desc: "At our Dharamkot Hostel, Enjoy the fresh air",
        img: "dharamkot_1.jpg"
    },
    "Mandrem": {
        desc: "At our Mandrem Goa Hostel, Enjoy the serene sunset",
        img: "mandrem_1.jpg"
    }
}
export {
    headerLinks,
    hostelLocations,
    urlMap,
    detailMap,
    galleryImageMap,
    footerLinks
}

export const IMAGE_BASE_URL = 'https://mitrahostel.com/';

// export const IMAGE_BASE_URL = 'http://localhost:3000/';