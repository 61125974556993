import React, { useState } from 'react'
import { headerLinks } from '../constants/common'
import { Bars3BottomRightIcon, XMarkIcon } from '@heroicons/react/20/solid'
import { IMAGE_BASE_URL } from "../constants/common";
import { useNavigate } from 'react-router-dom';

const SiteHeader = () => {
  const navigate = useNavigate();

  const [sidebarBoxVisibility, setSidebarBoxVisibility] = useState(false);

  const handleSidebarOpen = () => {
    setSidebarBoxVisibility(true);
  }

  const handleSidebarClose = () => {
    setSidebarBoxVisibility(false);
  }

  const handleNavLinkClick = (e) => {
    const clickedLink = () => {
      switch (e.target.id) {
        case 'destination':
          return '#destinationSection'
        case 'about':
          return '#aboutSection'
        case 'careers':
          return 'careers'

        default:
          return null
      }
    };

    if (clickedLink() !== 'careers') {
      const $sectionElement = $(clickedLink());


      if ($sectionElement && ($sectionElement.length > 0)) {
        const offset = 140;

        const targetPosition = $sectionElement.offset().top - offset;

        if ($(e.target).hasClass('mobileView')) {
          setSidebarBoxVisibility(false);
        };

        $("html, body").animate({
          scrollTop: targetPosition
        }, 1000);
      } else {
        navigate('/');

        setTimeout(() => {
          const $targetSection = $(clickedLink());

          if ($targetSection && $targetSection.length > 0) {
            const offset = 140;
            const targetPosition = $targetSection.offset().top - offset;

            $("html, body").animate({
              scrollTop: targetPosition
            }, 1000);
          }
        }, 1000);

      }

    } else if (clickedLink()) {
      // console.log("Careers");
      if ($(e.target).hasClass('mobileView')) {
        setSidebarBoxVisibility(false);
      };

      const origin = window.location.origin;

      // const pathname = window.location.pathname.split('/');
      // console.log(window.location);

      // console.log(pathname.filter(path => path.trim() !== "").join('/'));

      // const originPath = origin + '/' + pathname.filter(path => path.trim() !== "")[0] + '/';

      // console.log(originPath);
      window.open(`${origin}/careers`);
    };

  }

  return (
    <div className="glass flex justify-between items-center py-[10px] px-[30px] fixed z-[9999] w-screen h-[auto]">
      <img
        className="sm:max-md:w-[60px] sm:max-md:h-[60px] sm:max-md:mx-0 w-[100px] h-[100px] object-cover revealator-zoomin revealator-duration15 revealator-delay2"
        alt="mitra-logo"
        src={IMAGE_BASE_URL + "siteLogo.png"}
        style={{ cursor: 'pointer' }}
        onClick={() => navigate('/')}
      />
      <div>
        <span className='sm:block lg:hidden sidebarOpen' onClick={handleSidebarOpen}>
          <Bars3BottomRightIcon className='sm:w-[30px] sm:h-[30px] md:w-[50px] md:h-[50px] text-firebrick-100' />
        </span>
        <div className={`${sidebarBoxVisibility ? `sidebar-active` : ``} sm:block lg:hidden sidebar_box`}>
          <span className='absolute z-[99999] top-5 left-[32%] sm:w-[30px] sm:h-[30px] md:w-[50px] md:h-[50px] rounded-full bg-firebrick-100 flex justify-between items-center sidebarClose' onClick={handleSidebarClose}>
            <XMarkIcon className='sm:w-[30px] sm:h-[30px] md:w-[50px] md:h-[50px] text-white' />
          </span>
          <div className='h-[100%] w-[70%] ml-auto bg-black relative z-[9999]'>
            <div className='w-[100%] flex justify-center items-center mb-5'>
              <img
                className="sm:max-md:w-[110px] sm:max-md:h-[110px] object-cover"
                alt="mitra-logo"
                src={IMAGE_BASE_URL + "siteLogo.png"}
              />
            </div>

            <ul className=" m-0 text-xl p-[20px] text-right list-none font-['Poppins']">
              {headerLinks.map((item) => {
                return (<li key={item} className="text-white capitalise mt-3">
                  <a
                    className="text-white capitalize relative overflow-hidden no-underline hover:text-firebrick-100 decoration-firebrick-100 mobileView"
                    id={item.toLowerCase()}
                    onClick={handleNavLinkClick} >
                    {item}
                  </a>
                </li>)
              })
              }
            </ul>
          </div>

        </div>
        <ul className="font-['Poppins'] sm:max-md:mr-5 mx-10 sm:max-md:text-[1rem] text-xl list-none lg:flex gap-x-12 sm:max-md:gap-x-2 sm:hidden">
          {headerLinks.map((item, index) => {
            return (<li key={item} className={`text-white capitalise mt-3 revealator-slideleft revealator-duration15 ${(index === 0) ? `revealator-delay15` : `revealator-delay${15 + index * 5}`}`}>
              <a
                className="text-white capitalize relative overflow-hidden no-underline hover:text-firebrick-100 decoration-firebrick-100 "
                id={item.toLowerCase()}
                onClick={handleNavLinkClick}
              >
                {item}
              </a>
            </li>)
          })
          }
        </ul>
      </div>

    </div>
  )
}

export default SiteHeader