const ArambolLocationPage = () => {
  return (
    <div className="relative bg-floralwhite w-full h-[4461px] overflow-hidden text-left text-31xl text-floralwhite font-poppins">
      <div className="absolute top-[1773px] left-[-1px] w-[1730px] h-[826px] overflow-hidden">
        <div className="absolute top-[0px] left-[0px] w-[1730px] h-[826px] overflow-hidden">
          <div className="absolute top-[0px] left-[2px] w-[1728px] h-[826px] overflow-hidden bg-[url('/public/photo_hero.png')] bg-cover bg-no-repeat bg-[top]">
            <div className="absolute top-[-7px] left-[0px] [background:linear-gradient(90.29deg,_rgba(0,_0,_0,_0.5)_57.54%,_rgba(0,_0,_0,_0)_94.28%)] [backdrop-filter:blur(70px)] w-[1728px] h-[833px]" />
            <div className="absolute top-[-7px] left-[190px] w-[1347px] h-[731px] overflow-hidden">
              <div className="absolute top-[0px] left-[0px] w-[548px] h-[731px] overflow-hidden">
                <div className="absolute top-[183px] left-[0px] w-[548px] h-[548px] overflow-hidden bg-[url('/public/photo_hero.png')] bg-cover bg-no-repeat bg-[top]">
                  <div className="absolute top-[345px] left-[0px] [background:linear-gradient(179.94deg,_rgba(0,_0,_0,_0),_rgba(0,_0,_0,_0.68)_67.59%,_#000)] w-[548px] h-[203px] overflow-hidden flex flex-col items-center justify-center py-[50px] px-[122px] box-border gap-[13px]">
                    <b className="relative">Dorm Room</b>
                    <div className="rounded-md bg-firebrick-100 w-[285px] h-[65px] flex flex-row items-center justify-center py-2.5 px-[84px] box-border mix-blend-normal text-8xl">
                      <div className="relative font-medium">Book now</div>
                    </div>
                  </div>
                </div>
              </div>
              <div className="absolute top-[0px] left-[475px] w-[872px] h-[731px] overflow-hidden text-center">
                <div className="absolute top-[0px] left-[324px] w-[548px] h-[731px] overflow-hidden">
                  <div className="absolute top-[183px] left-[0px] w-[548px] h-[548px] overflow-hidden bg-[url('/public/private-room.png')] bg-cover bg-no-repeat bg-[top]">
                    <div className="absolute top-[345px] left-[0px] [background:linear-gradient(179.94deg,_rgba(0,_0,_0,_0),_rgba(0,_0,_0,_0.68)_67.59%,_#000)] w-[548px] h-[203px] overflow-hidden flex flex-col items-center justify-center py-[42px] px-[107px] box-border gap-[21px]">
                      <b className="relative">Private room</b>
                      <div className="rounded-md bg-firebrick-100 w-[285px] h-[65px] flex flex-row items-center justify-center py-2.5 px-[84px] box-border mix-blend-normal text-left text-8xl">
                        <div className="relative font-medium">Book now</div>
                      </div>
                    </div>
                  </div>
                </div>
                <div className="absolute top-[0px] left-[0px] w-[400px] h-[136px] overflow-hidden text-left text-51xl">
                  <b className="absolute top-[31px] left-[calc(50%_-_200px)]">
                    Room Type
                  </b>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div className="absolute top-[1094px] left-[0px] w-[1728px] h-[3367px] overflow-hidden text-51xl">
        <div className="absolute top-[1505px] left-[0px] w-[1728px] h-[1862px] overflow-hidden">
          <div className="absolute top-[0px] left-[0px] bg-firebrick-200 w-[1728px] h-[826px] overflow-hidden">
            <img
              className="absolute top-[135px] left-[calc(50%_-_735px)] w-[1472px] h-[665px] overflow-hidden object-cover"
              alt=""
              src="/frame-931@2x.png"
            />
            <b className="absolute top-[24px] left-[calc(50%_-_233px)]">
              How to reach
            </b>
          </div>
          <div className="absolute top-[826px] left-[0px] w-[1728px] h-[1036px] overflow-hidden text-justify text-xl text-black">
            <div className="absolute top-[679px] left-[10px] w-[1708px] h-[357px] overflow-hidden">
              <div className="absolute top-[0px] left-[calc(50%_-_853px)] w-[1707px] overflow-hidden flex flex-row items-center justify-start py-[47px] px-[13px] box-border gap-[320px]">
                <div className="relative bg-floralwhite w-[368px] h-[249px] overflow-hidden shrink-0">
                  <img
                    className="absolute top-[calc(50%_-_124.5px)] left-[calc(50%_-_184px)] w-[455px] h-[323px] object-cover"
                    alt=""
                    src="/siteLogo2x.png"
                  />
                </div>
                <div className="relative bg-floralwhite w-[274px] h-[263px] overflow-hidden shrink-0">
                  <div className="absolute top-[28px] left-[12px] w-[250px] h-[201px]">
                    <div className="absolute top-[0px] left-[calc(50%_-_125px)] inline-block w-[250px]">
                      Privacy policy
                    </div>
                    <div className="absolute top-[57px] left-[calc(50%_-_125px)] inline-block w-[250px]">
                      Refund policy
                    </div>
                    <div className="absolute top-[114px] left-[calc(50%_-_125px)] inline-block w-[90.7px]">
                      F.A.Q
                    </div>
                    <div className="absolute top-[171px] left-[calc(50%_-_125px)] inline-block w-[109.5px]">
                      About
                    </div>
                  </div>
                </div>
                <div className="bg-floralwhite w-[406px] overflow-hidden shrink-0 flex flex-row flex-wrap items-center justify-center py-0 px-3 box-border text-left text-base">
                  <div className="relative inline-block w-[409px] h-[52px] shrink-0">
                    Connect with us on your favorite social media ..
                  </div>
                  <div className="flex flex-row items-center justify-center gap-[52px] ml-[-21px]">
                    <img
                      className="relative w-[45px] h-[45px] overflow-hidden shrink-0"
                      alt=""
                      src="/instagram-logo.svg"
                    />
                    <img
                      className="relative w-[45px] h-[45px] overflow-hidden shrink-0"
                      alt=""
                      src="/facebook-logo.svg"
                    />
                  </div>
                </div>
              </div>
            </div>
            <div className="absolute top-[0px] left-[calc(50%_-_864px)] bg-firebrick-200 w-[1728px] h-[697px] overflow-hidden text-left text-50xl text-floralwhite">
              <div className="absolute top-[-175px] left-[14px] w-[1701px] h-[854px] overflow-hidden">
                <div className="absolute top-[calc(50%_-_233px)] left-[calc(50%_-_850.5px)] bg-floralwhite w-[1701px] h-[660px]" />
              </div>
              <div className="absolute top-[-175px] left-[57px] w-[1721px] h-[980px] overflow-hidden">
                <div className="absolute top-[237px] left-[1126px] w-[481px] h-[574px] overflow-hidden text-4xl">
                  <div className="absolute top-[0px] left-[0px] w-[481px] h-[574px] bg-[url('/public/frame-99@3x.png')] bg-cover bg-no-repeat bg-[top]">
                    <div className="absolute top-[313px] left-[0px] [background:linear-gradient(180deg,_rgba(72,_72,_72,_0),_rgba(0,_0,_0,_0.95))] w-[481px] h-[261px]" />
                    <div className="absolute top-[485px] left-[109px] rounded-8xs bg-firebrick-200 w-[262px] flex flex-row items-center justify-center py-2.5 px-[84px] box-border mix-blend-normal">
                      <div className="relative font-medium">Explore now</div>
                    </div>
                    <div className="absolute top-[335px] left-[calc(50%_-_213.5px)] text-50xl font-semibold text-center">
                      McLeodganj
                    </div>
                  </div>
                </div>
                <div className="absolute top-[237px] left-[566px] w-[481px] h-[573.7px] bg-[url('/public/cardloc2@3x.jpg')] bg-cover bg-no-repeat bg-[top]">
                  <div className="absolute top-[313px] left-[0px] [background:linear-gradient(180deg,_rgba(72,_72,_72,_0),_rgba(0,_0,_0,_0.95))] w-[481px] h-[261px] overflow-hidden">
                    <div className="absolute top-[22px] left-[calc(50%_-_143.5px)] font-semibold">
                      Vagator
                    </div>
                  </div>
                  <div className="absolute top-[485px] left-[109px] rounded-8xs bg-firebrick-200 w-[262px] flex flex-row items-center justify-center py-2.5 px-[84px] box-border mix-blend-normal text-4xl">
                    <div className="relative font-medium">Explore now</div>
                  </div>
                </div>
                <div className="absolute top-[237px] left-[12px] w-[481px] h-[573.7px] bg-[url('/public/cardloc1@3x.jpg')] bg-cover bg-no-repeat bg-[top] text-center">
                  <div className="absolute top-[313px] left-[0px] [background:linear-gradient(180deg,_rgba(72,_72,_72,_0),_rgba(0,_0,_0,_0.95))] w-[481px] h-[261px] overflow-hidden">
                    <div className="absolute top-[22px] left-[calc(50%_-_162.5px)] font-semibold">
                      Sonmarg
                    </div>
                  </div>
                  <div className="absolute top-[485px] left-[109px] rounded-8xs bg-firebrick-200 w-[262px] flex flex-row items-center justify-center py-2.5 px-[84px] box-border mix-blend-normal text-left text-4xl">
                    <div className="relative font-medium">Explore now</div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div className="absolute top-[0px] left-[0px] bg-firebrick-200 w-[1728px] h-[697px] overflow-hidden text-center text-11xl">
          <div className="absolute top-[19px] left-[14px] w-[1701px] h-[660px] overflow-hidden">
            <div className="absolute top-[0px] left-[0px] w-[1701px] h-[660px]">
              <div className="absolute top-[calc(50%_-_361px)] left-[calc(50%_-_890.5px)] bg-firebrick-200 w-[1781px] h-[691px]" />
              <div className="absolute top-[-31px] left-[335px] w-[1032px] h-[188px] overflow-hidden">
                <div className="absolute top-[143px] left-[calc(50%_-_516px)] [text-shadow:0px_0px_1px_rgba(0,_0,_0,_0.75)]">{`At our Arambol, Goa hostel, we invite you to let the spirit of adventure `}</div>
              </div>
            </div>
            <div className="absolute top-[0px] left-[24px] w-[427px] h-[613px] overflow-hidden">
              <img
                className="absolute top-[185px] left-[0px] w-[427px] h-[428px] overflow-hidden object-cover"
                alt=""
                src="/frame-932@2x.png"
              />
            </div>
            <div className="absolute top-[0px] left-[475px] w-[1330px] h-[613px] overflow-hidden text-51xl">
              <div className="absolute top-[0px] left-[903px] w-[427px] h-[613px] overflow-hidden">
                <img
                  className="absolute top-[185px] left-[0px] w-[427px] h-[428px] overflow-hidden object-cover"
                  alt=""
                  src="/frame-932@2x.png"
                />
              </div>
              <div className="absolute top-[0px] left-[0px] w-[879px] h-[613px] overflow-hidden">
                <div className="absolute top-[0px] left-[452px] w-[427px] h-[613px] overflow-hidden">
                  <img
                    className="absolute top-[185px] left-[0px] w-[427px] h-[428px] overflow-hidden object-cover"
                    alt=""
                    src="/frame-932@2x.png"
                  />
                </div>
                <div className="absolute top-[0px] left-[0px] w-[505px] h-[613px] overflow-hidden">
                  <div className="absolute top-[0px] left-[0px] w-[428px] h-[613px] overflow-hidden">
                    <img
                      className="absolute top-[185px] left-[0px] w-[428px] h-[428px] overflow-hidden object-cover"
                      alt=""
                      src="/frame-941@2x.png"
                    />
                  </div>
                  <div className="absolute top-[0px] left-[245px] w-[260px] h-[118px] overflow-hidden">
                    <b className="absolute top-[13px] left-[calc(50%_-_130px)]">
                      Gallery
                    </b>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div className="absolute top-[0px] left-[-1px] w-[1730px] h-[1094px] overflow-hidden text-center text-11xl">
        <div className="absolute top-[0px] left-[2px] bg-black w-[2308px] h-[1094px] overflow-hidden">
          <div className="absolute top-[0px] left-[0px] w-[1728px] h-[1094px] overflow-hidden">
            <img
              className="absolute top-[0px] left-[0px] w-[1727px] h-[1094px] object-cover"
              alt=""
              src="/8asovra9hft5e4vfhl54o9hg0gha-arambol-beach-1@2x.jpg"
            />
            <div className="absolute top-[-116px] left-[-1px] w-[1728px] h-[245px] overflow-hidden" />
          </div>
        </div>
        <div className="absolute top-[0px] left-[0px] [background:linear-gradient(180deg,_rgba(0,_0,_0,_0.79),_rgba(0,_0,_0,_0.53)_48.96%,_rgba(0,_0,_0,_0.79))] w-[1729px] h-[1094px] overflow-hidden" />
        <div className="absolute top-[380px] left-[348px] w-[1034px] h-[238px] overflow-hidden">
          <div className="absolute top-[193px] left-[0px] w-[1034px] h-[45px] overflow-hidden">
            <div className="absolute top-[0px] left-[calc(50%_-_515px)] [text-shadow:0px_0px_1px_rgba(0,_0,_0,_0.75)]">{`At our Arambol, Goa hostel, we invite you to let the spirit of adventure `}</div>
          </div>
          <div className="absolute top-[0px] left-[221px] w-[592px] h-[200px] overflow-hidden text-left text-114xl">
            <div className="absolute top-[0px] left-[calc(50%_-_295px)] font-semibold">
              Arambol
            </div>
          </div>
        </div>
        <div className="absolute top-[650px] left-[722px] w-[286px] h-[65px] overflow-hidden text-left text-8xl">
          <div className="absolute top-[0px] left-[1px] rounded-md bg-firebrick-100 w-[285px] h-[65px] flex flex-row items-center justify-center py-2.5 px-[84px] box-border mix-blend-normal">
            <div className="relative font-medium">Book now</div>
          </div>
        </div>
      </div>
      <div className="absolute top-[0px] left-[-1px] bg-black w-[1729px] h-[126px] overflow-hidden" />
      <div className="absolute top-[0px] left-[0px] bg-gray-300 [backdrop-filter:blur(13px)] w-[1729px] overflow-hidden flex flex-row items-center justify-center py-0 px-[95px] box-border text-center text-5xl">
        <div className="w-[1727px] flex flex-row items-center justify-center gap-[950px]">
          <img
            className="relative w-[143px] h-[126px] object-cover"
            alt=""
            src="/logo-21@2x.png"
          />
          <div className="flex flex-row flex-wrap items-center justify-center gap-[107px]">
            <div className="relative text-left">Destination</div>
            <div className="relative">About</div>
            <div className="relative">Contact</div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default ArambolLocationPage;
