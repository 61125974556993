import React from 'react'
import Slider from "react-slick";
// Import css files
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";


function classNames(...classes) {
  return classes.filter(Boolean).join(' ')
}

const settings = {
  dots: false,
  arrors: false,
  infinite: true,
  slidesToShow: 4.5,
  slidesToScroll: 1,
  adaptiveHeight: true,
  autoplay: true,
  autoplaySpeed: 3000,
  responsive: [
    {
      breakpoint: 1024, // Desktop
      settings: {
        slidesToShow: 4,
      },
    },
    {
      breakpoint: 768, // Tablet
      settings: {
        slidesToShow: 3,
      },
    },
    {
      breakpoint: 480, // Mobile
      settings: {
        slidesToShow: 2,
      },
    },
  ],
};

function getGalleryImages({ location }) {
  if (location == "Arambol") {
    return (
      <Slider {...settings} >
        <div className="bg-center bg-cover hover:scale-105 ease-in duration-300 drop-shadow-xl hover:drop-shadow-[0_55px_25px_rgba(255,255,255,0.55)] w-[350px] h-[500px] sm:max-md:h-[250px] sm:max-md:w-[175px] bg-[url('/public/gallery/arambol/armb-1.jpg')]"></div>
        <div className="bg-center bg-cover hover:scale-105 ease-in duration-300 drop-shadow-xl hover:drop-shadow-[0_55px_25px_rgba(255,255,255,0.55)] w-[350px] h-[500px] sm:max-md:h-[250px] sm:max-md:w-[175px] bg-[url('/public/gallery/arambol/armb-2.jpg')]"></div>
        <div className="bg-center bg-cover hover:scale-105 ease-in duration-300 drop-shadow-xl hover:drop-shadow-[0_55px_25px_rgba(255,255,255,0.55)] w-[350px] h-[500px] sm:max-md:h-[250px] sm:max-md:w-[175px] bg-[url('/public/gallery/arambol/armb-3.jpg')]"></div>
        <div className="bg-center bg-cover hover:scale-105 ease-in duration-300 drop-shadow-xl hover:drop-shadow-[0_55px_25px_rgba(255,255,255,0.55)] w-[350px] h-[500px] sm:max-md:h-[250px] sm:max-md:w-[175px] bg-[url('/public/gallery/arambol/armb-4.jpg')]"></div>
        <div className="bg-center bg-cover hover:scale-105 ease-in duration-300 drop-shadow-xl hover:drop-shadow-[0_55px_25px_rgba(255,255,255,0.55)] w-[350px] h-[500px] sm:max-md:h-[250px] sm:max-md:w-[175px] bg-[url('/public/gallery/arambol/armb-5.jpg')]"></div>
        <div className="bg-center bg-cover hover:scale-105 ease-in duration-300 drop-shadow-xl hover:drop-shadow-[0_55px_25px_rgba(255,255,255,0.55)] w-[350px] h-[500px] sm:max-md:h-[250px] sm:max-md:w-[175px] bg-[url('/public/gallery/arambol/armb-6.jpg')]"></div>
      </Slider>
    )
  }
  else if (location == "Sonmarg") {
    return (
      <Slider {...settings} >
        <div className="bg-center bg-cover hover:scale-105 ease-in duration-300 drop-shadow-xl hover:drop-shadow-[0_55px_25px_rgba(255,255,255,0.55)] w-[350px] h-[500px] sm:max-md:h-[250px] sm:max-md:w-[175px] bg-[url('/public/sonmarg-1.jpg')]"></div>
        <div className="bg-center bg-cover hover:scale-105 ease-in duration-300 drop-shadow-xl hover:drop-shadow-[0_55px_25px_rgba(255,255,255,0.55)] w-[350px] h-[500px] sm:max-md:h-[250px] sm:max-md:w-[175px] bg-[url('/public/sonmarg-1.jpg')]"></div>
        <div className="bg-center bg-cover hover:scale-105 ease-in duration-300 drop-shadow-xl hover:drop-shadow-[0_55px_25px_rgba(255,255,255,0.55)] w-[350px] h-[500px] sm:max-md:h-[250px] sm:max-md:w-[175px] bg-[url('/public/sonmarg-1.jpg')]"></div>
      </Slider>
    )
  }
  else if (location == "Vagator") {
    return (
      <Slider {...settings} >
        <div className="bg-center bg-cover hover:scale-105 ease-in duration-300 drop-shadow-xl hover:drop-shadow-[0_55px_25px_rgba(255,255,255,0.55)] w-[350px] h-[500px] sm:max-md:h-[250px] sm:max-md:w-[175px] bg-[url('/public/gallery/vagator/vag-1.jpg')]"></div>
        <div className="bg-center bg-cover hover:scale-105 ease-in duration-300 drop-shadow-xl hover:drop-shadow-[0_55px_25px_rgba(255,255,255,0.55)] w-[350px] h-[500px] sm:max-md:h-[250px] sm:max-md:w-[175px] bg-[url('/public/gallery/vagator/vag-2.jpg')]"></div>
        <div className="bg-center bg-cover hover:scale-105 ease-in duration-300 drop-shadow-xl hover:drop-shadow-[0_55px_25px_rgba(255,255,255,0.55)] w-[350px] h-[500px] sm:max-md:h-[250px] sm:max-md:w-[175px] bg-[url('/public/gallery/vagator/vag-3.jpg')]"></div>
        <div className="bg-center bg-cover hover:scale-105 ease-in duration-300 drop-shadow-xl hover:drop-shadow-[0_55px_25px_rgba(255,255,255,0.55)] w-[350px] h-[500px] sm:max-md:h-[250px] sm:max-md:w-[175px] bg-[url('/public/gallery/vagator/vag-4.jpg')]"></div>
        <div className="bg-center bg-cover hover:scale-105 ease-in duration-300 drop-shadow-xl hover:drop-shadow-[0_55px_25px_rgba(255,255,255,0.55)] w-[350px] h-[500px] sm:max-md:h-[250px] sm:max-md:w-[175px] bg-[url('/public/gallery/vagator/vag-5.jpg')]"></div>
        <div className="bg-center bg-cover hover:scale-105 ease-in duration-300 drop-shadow-xl hover:drop-shadow-[0_55px_25px_rgba(255,255,255,0.55)] w-[350px] h-[500px] sm:max-md:h-[250px] sm:max-md:w-[175px] bg-[url('/public/gallery/vagator/vag-6.jpg')]"></div>
        <div className="bg-center bg-cover hover:scale-105 ease-in duration-300 drop-shadow-xl hover:drop-shadow-[0_55px_25px_rgba(255,255,255,0.55)] w-[350px] h-[500px] sm:max-md:h-[250px] sm:max-md:w-[175px] bg-[url('/public/gallery/vagator/vag-7.jpg')]"></div>
        <div className="bg-center bg-cover hover:scale-105 ease-in duration-300 drop-shadow-xl hover:drop-shadow-[0_55px_25px_rgba(255,255,255,0.55)] w-[350px] h-[500px] sm:max-md:h-[250px] sm:max-md:w-[175px] bg-[url('/public/gallery/vagator/vag-8.jpg')]"></div>
        <div className="bg-center bg-cover hover:scale-105 ease-in duration-300 drop-shadow-xl hover:drop-shadow-[0_55px_25px_rgba(255,255,255,0.55)] w-[350px] h-[500px] sm:max-md:h-[250px] sm:max-md:w-[175px] bg-[url('/public/gallery/vagator/vag-9.jpg')]"></div>
        <div className="bg-center bg-cover hover:scale-105 ease-in duration-300 drop-shadow-xl hover:drop-shadow-[0_55px_25px_rgba(255,255,255,0.55)] w-[350px] h-[500px] sm:max-md:h-[250px] sm:max-md:w-[175px] bg-[url('/public/gallery/vagator/vag-10.jpg')]"></div>
        <div className="bg-center bg-cover hover:scale-105 ease-in duration-300 drop-shadow-xl hover:drop-shadow-[0_55px_25px_rgba(255,255,255,0.55)] w-[350px] h-[500px] sm:max-md:h-[250px] sm:max-md:w-[175px] bg-[url('/public/gallery/goa/goa-1.jpg')]"></div>
        <div className="bg-center bg-cover hover:scale-105 ease-in duration-300 drop-shadow-xl hover:drop-shadow-[0_55px_25px_rgba(255,255,255,0.55)] w-[350px] h-[500px] sm:max-md:h-[250px] sm:max-md:w-[175px] bg-[url('/public/gallery/goa/goa-2.jpg')]"></div>
        <div className="bg-center bg-cover hover:scale-105 ease-in duration-300 drop-shadow-xl hover:drop-shadow-[0_55px_25px_rgba(255,255,255,0.55)] w-[350px] h-[500px] sm:max-md:h-[250px] sm:max-md:w-[175px] bg-[url('/public/gallery/goa/goa-3.jpg')]"></div>
        <div className="bg-center bg-cover hover:scale-105 ease-in duration-300 drop-shadow-xl hover:drop-shadow-[0_55px_25px_rgba(255,255,255,0.55)] w-[350px] h-[500px] sm:max-md:h-[250px] sm:max-md:w-[175px] bg-[url('/public/gallery/goa/goa-4.jpg')]"></div>
        <div className="bg-center bg-cover hover:scale-105 ease-in duration-300 drop-shadow-xl hover:drop-shadow-[0_55px_25px_rgba(255,255,255,0.55)] w-[350px] h-[500px] sm:max-md:h-[250px] sm:max-md:w-[175px] bg-[url('/public/gallery/goa/goa-5.jpg')]"></div>
      </Slider>
    )
  }
}

const Gallery = ({ images, location }) => {
  return (
    <div className='bg-firebrick-100 overflow-hidden pt-[30px]'>
      <div className='relative text-floralwhite flex flex-col items-center top-[2%] h-[10%] w-full revealator-zoomin revealator-duration15 revealator-delay10'>
        <div className='font-bold text-[3rem] mb-3 sm:max-md:text-[2rem]'>Gallery</div>
        <div className='text-[1.6rem] sm:max-md:text-[1.5rem] mb-[30px] text-center'>What you could be doing at {location}</div>
      </div>
      <div className='relative w-full top-[10%] mb-10'>
        {
          getGalleryImages({ location })
        }
      </div>
    </div>
  )
}

export default Gallery