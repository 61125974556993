import SiteHeader from "../components/header";
import LocationCard from "../components/locationCard";
import Dropdown from "../components/dropdown";
import { DEFAULT_LOCATION } from "../constants/home-page";
import { useEffect, useState } from 'react'
import { urlMap } from "../constants/common";
import FooterComponent from "../components/footer";
import { IMAGE_BASE_URL } from "../constants/common";

const HomePage = () => {
  const [locationSelection, setLocation] = useState(() => DEFAULT_LOCATION);

  useEffect(() => {
    // console.log("Loadedd");
    $(window).trigger("scroll");
    Revealator.effects_padding = '-550';
  }, [])

  const handleBookNowClick = () => {
    // console.log(window.location);

    const origin = window.location.origin;

    // const pathname = window.location.pathname.replaceAll('/', '');

    // const originPath = origin + '/' + pathname + '/';

    window.open(`${origin}/${urlMap[locationSelection]}`);
  };

  return (
    <div className="p-0 bg-black">
      <SiteHeader />

      <div
        className="w-screen bg-cover lg:h-[750px] sm:h-[700px] bg-[url('/public/hero_homepage.png')] bg-no-repeat bg-center"
      >
        <div className="relative [background:linear-gradient(90deg,_#000,_rgba(0,_0,_0,_0))] h-full w-full">
          <div className="h-full top-[80px] sm:top-[50%] w-full overflow-hidden lg:flex md:flex sm:flex flex-row sm:max-md:flex-col items-center sm:justify-center lg:md:xl:justify-start text-left text-[4rem] text-floralwhite">
            <div className="overflow-visible shrink-1">
              <div className="relative lg:h-[auto] sm:max-md:h-[auto] top-0 lg:w-[60%] sm:max-md:w-full overflow-visible lg:flex flex-column Lg:items-center justify-center revealator-slideright revealator-duration15 revealator-delay2">
                <div className="relative h-[auto] sm:h-[auto] top-[10%] sm:top-[40%] left-[0%] w-full px-[30px] lg:flex flex-col items-start sm:block lg:justify-center gap-[5%]">
                  <div className="inline-block font-['Poppins'] sm:max-md:text-[2.5rem] w-[100%] sm:max-md:w-full sm:mb-5">
                    Let the spirit of adventure guide you
                  </div>
                  <div className="relative h-full lg:w-[100%] md:w-[100%] lg:flex md:flex lg:align-center md:align-center flex-row sm:block items-start overflow-visible justify-start lg:gap-[1rem] md:gap-[1rem] text-8xl">
                    <Dropdown location={locationSelection} setLocation={setLocation} />
                    <button
                      onClick={handleBookNowClick}
                      className="rounded-md cursor-pointer text-floralwhite sm:max-md:text-[1.2rem] text-[1.5rem] bg-firebrick-100 lg:w-[30%] lg:h-[65px] sm:h-[65px] md:h-[65px] sm:w-[100%] md:w-[40%] sm:mt-4 lg:mt-0 md:mt-0 py-2.5 px-[2%] box-border">
                      Book now
                    </button>
                  </div>
                </div>
              </div>
            </div>
            <div className="w-[40%] h-[100%] absolute right-0 bottom-0 sm:hidden lg:block revealator-slideleft revealator-duration15 revealator-delay5">
              <img
                className="w-[100%] h-[100%] object-cover sm:max-md:object-cover"
                alt=""
                src={IMAGE_BASE_URL + "header-image@2x.jpg"}
              />
            </div>
          </div>
        </div>
      </div>
      <div className="w-full lg:bg-white sm:bg-firebrick-100 py-[50px] flex items-center justify-center" id="mitraHostelsInIndia">
        <div className="lg:w-[50%] sm:w-[90%] sm:text-center lg:text-center md:text-center sm:text-firebrick-100 sm:text-white revealator-zoomin revealator-duration15 revealator-delay15">
          <div className="relative top-[10%] xl:text-[50px] lg:text-[40px] sm:text-[26px] md:text-[26px] font-bold font-['Poppins'] lg:text-firebrick-100 sm:text-white ">Mitra Hostels in India...</div>
          <span className="relative xl:text-[30px] sm:max-md:text-[0.8rem] lg:text-firebrick-100 font-['Poppins'] sm:text-white">in Heavenly locations like Kashmir, Goa & Himachal Pradesh</span>
        </div>
      </div>
      <div className="grid sm:gap-2 lg:gap-0 bg-white" id="destinationSection">
        <LocationCard location="Vagator" />
        <LocationCard location="Arambol" inverted={true} />
        <LocationCard location="Sonmarg" />
        <LocationCard location="Dharamkot" inverted={true} />
        <LocationCard location="Mandrem" />
      </div>

      <div className="p-3 bg-floralwhite" id="aboutSection">
        <div className="text-firebrick-100 m-0 shrink-1 object-cover box-border border-solid border-[2.5px] mt-0 border-firebrick-100 text-center w-[100%] p-10 text-center text-firebrick-100 inline-block bg-floralwhite font-['Poppins'] [text-shadow:0px_0px_1px_rgba(0,_0,_0,_0.75)] revealator-zoomin revealator-duration15 revealator-delay2">
          <div className="lg:w-[60%] sm:w-[100%] m-auto">
            <div className="relative top-[10%] text-[2.1rem] sm:max-md:text-[1.5rem] font-bold">About Us</div>
            <span className="relative text-[1.5rem] sm:max-md:text-[0.8rem] font-bold">Welcome to Mitra Hostel</span>
            <span className="relative text-[1.2rem] sm:max-md:text-[0.7rem]">&nbsp;- your budget-friendly, community-focused accommodation option for a unique travel experience. Our mission is to make our guests feel like they're part of a group.</span>
          </div>

        </div>
      </div>

      <FooterComponent />
    </div>
  );
};

export default HomePage;
