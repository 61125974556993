import SiteHeader from "../components/header";
import LocationCard from "../components/locationCard";
import Dropdown from "../components/dropdown";
import FooterComponent from "../components/footer";
import { IMAGE_BASE_URL } from "../constants/common";
import { useEffect, useState } from "react";

const Careers = () => {
  const [name, setName] = useState('')
  const [email, setEmail] = useState('')
  const [number, setNumber] = useState('')
  const [applyingFor, setApplyingFor] = useState('')
  const _sendForm = () => {
    // console.log(name, email, number, applyingFor);
    const formData = new FormData();
    formData.append('name', name);
    formData.append('email', email);
    formData.append('number', number);
    formData.append('applyingFor', applyingFor);
    fetch("https://mitrahostel.com/php_s/action.php", {
      method: "POST",
      body: formData
    }).then((response) => {
      const statusCode = response.status;
      const data = response.json();
      return Promise.all([statusCode, data]);
    }).then(([status, response]) => {
      // console.log(status, response);
      if (response) {
        Swal.fire({
          icon: 'success',
          title: 'Applied Successfully!',
          text: 'You are seccessfully applied for the job.',
        })
      } else {
        Swal.fire({
          icon: 'error',
          title: 'Something went wrong!',
          text: 'Please try again later.',
        })
      }
    }).catch((error) => {
      // console.error("_sendForm error", error);
      Swal.fire({
        icon: 'error',
        title: 'Internal server error!',
        text: 'Please try again later.',
      })
    })
  }

  useEffect(() => {
    // console.log("Loadedd");
    $(window).trigger("scroll");
    Revealator.effects_padding = '-550';
  }, [])

  return (
    <div className="p-0 bg-black">
      <SiteHeader />

      <div
        className="bg-[url('/public/hero_homepage.jpg')] bg-cover bg-no-repeat bg-center"
      //
      >
        <div className="relative w-full h-full after_box flex flex-row items-center justify-center">
          <div className="relative z-10 h-[500px] sm:max-md:h-[320px] top-0 w-[70%] sm:max-md:w-full text-center text-gray-50 overflow-visible flex flex-row items-center justify-center">
            <div className="relative h-[auto] top-[0] left-[0] w-full flex flex-col items-center text-center justify-start gap-[5%]">
              <div className="inline-block font-['Poppins'] font-bold lg:text-[90px] sm:text-[55px] revealator-zoomin revealator-duration15 revealator-delay2">
                Careers
              </div>
              <div className="relative top-[0] h-full w-full flex flex-col items-center overflow-visible justify-center gap-[25px] text-8xl">
                <div className="inline-block font-['Poppins'] text-[25px] sm:max-md:text-[20px] revealator-slideup revealator-duration15 revealator-delay10">
                  Join us for an amazing opportunity
                </div>

              </div>
            </div>
          </div>
        </div>
      </div>
      <div className="w-full lg:bg-white sm:bg-firebrick-100 py-[50px] flex items-center justify-center">
        <div className="lg:w-[30%] sm:w-[90%] sm:text-firebrick-100 sm:text-white revealator-zoomin revealator-duration15 revealator-delay15">
          <div className="shadow-xl sm:bg-white p-[30px] rounded-2xl">
            {/* <form action="mailto:recipient_email@example.com"
              method="post"
              encType="text/plain"
              className="space-y-8"
            > */}
            <div className="space-y-8">
              <div>
                <label htmlFor="name" className="block mb-2 text-sm font-medium text-gray-900 dark:text-gray-300">Your Name</label>
                <input type="name" value={name} onChange={event => setName(event.target.value)} id="name" className="shadow-sm bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-md focus:ring-primary-500 focus:border-primary-500 block w-full p-2.5 " placeholder="Your Name" required />
              </div>
              <div>
                <label htmlFor="email" className="block mb-2 text-sm font-medium text-gray-900 dark:text-gray-300">Your Email</label>
                <input type="email" value={email} onChange={event => setEmail(event.target.value)} id="email" className="shadow-sm bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-md focus:ring-primary-500 focus:border-primary-500 block w-full p-2.5 " placeholder="Your Email" required />
              </div>
              <div>
                <label htmlFor="phoneNumber" className="block mb-2 text-sm font-medium text-gray-900 dark:text-gray-300">Phone Number</label>
                <input type="number" value={number} onChange={event => setNumber(event.target.value)} id="phoneNumber" className="block p-3 w-full text-sm text-gray-900 bg-gray-50 rounded-md border border-gray-300 shadow-sm focus:ring-primary-500 focus:border-primary-500 " placeholder="Phone Number" required />
              </div>

              <div className="sm:col-span-2 ">
                <label className="block mb-2 text-sm font-medium text-gray-900 dark:text-gray-300" htmlFor="grid-state">
                  Applying for :
                </label>
                <div className="relative">
                  <select value={applyingFor} onChange={event => setApplyingFor(event.target.value)} className="block p-2.5 w-full text-sm text-gray-900 bg-gray-50 rounded-md shadow-sm border border-gray-300 focus:ring-primary-500 focus:border-primary-500 " id="grid-state">
                    <option value="">Select One</option>
                    <option>Graphics Volunteer</option>
                    <option> Property Manager</option>
                    <option>General Manager</option>
                  </select>
                </div>
              </div>
              <button type="button" onClick={_sendForm} className="rounded-md cursor-pointer text-white sm:max-md:text-[1.2rem] text-[1.5rem] bg-firebrick-100 lg:w-[100%] lg:h-[55px] sm:h-[55px] md:h-[55px] sm:w-[100%] md:w-[40%] sm:mt-4 lg:mt-0 md:mt-0 py-2.5 px-[2%] box-border text-[18px]">Send message</button>
              {/* </form> */}
            </div>
          </div>


        </div>
      </div>


      <FooterComponent />
    </div>
  );
};

export default Careers;
